import {Link} from "react-router-dom";
import React from "react";

const SimpleNavigation = () => {

    return (
        <>
            <Link to={"/"}> Logo to redirec to index </Link>
        </>
    )


}

export default SimpleNavigation;