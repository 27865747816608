import backendData from "./backendData";
import backendData1 from "./backendData1";

const backendColumns = {
    1: {
        name: "1",
        items: backendData
    },
    2: {
        name: "2",
        items: backendData1
    }
};

export default backendColumns;