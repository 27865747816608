import React from 'react'
import WeatherBoard from "../WeatherBoard/WeatherBoard";

const Dashboard = () => {
    return (
        <>
            <WeatherBoard/>
        </>

    )
}

export default Dashboard;